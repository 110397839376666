@import "src/scss/module";

.jobs {
  [class*="carousel-control-"] {
    svg {
      fill: var(#{--bs-primary});
    }
  }

  [class="carousel-control-prev"] {
    left: initial;
    right: 30px;
    bottom: initial;
    width: 30px;
    top: -50px;
  }

  [class="carousel-control-next"] {
    bottom: initial;
    width: 30px;
    top: -50px;
  }

  [class="carousel-inner"] {
    padding-top: $spacer * 0.5;
  }

  &__filter {
    align-items: center;

    a {
      background-color: $white;
      color: $gray-600;
      text-decoration: none;
      border-radius: var(#{--bs-border-radius});
      border: 1px solid $gray-600;
      padding: $spacer * 0.5 $spacer;
      cursor: pointer;
      font-weight: 600;

      &[class*="active"] {
        background-color: $white;
        color: var(#{--bs-secondary});
        border-color: var(#{--bs-secondary});
      }
    }
  }

  &__footer {
    text-align: right;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    color: var(#{--bs-primary});
    text-decoration: none;

    & svg {
      fill: var(#{--bs-primary});
    }
  }
}
